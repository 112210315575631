<template>
    <form
        class="search"
        :class="{open: showSearch}"
        :action="search_route"
        method="GET"
    >
        <div class="search_inner">
            <div class="search_autocomplete">
                <input
                    class="search_val"
                    type="text"
                    name="query"
                    :placeholder="placeholder_text"
                    v-model="search_input"
                    @input="fetchSuggestions"
                />
                <div class="search_list">
                    <ul
                        :style="{ display: suggestions.length ? 'block' : 'none' }"
                    >
                        <li v-for="(suggestion, index) in suggestions" :key="index">
                            <div class="eac-item">
                                <a :href="suggestion.link">
                                    <span>{{ suggestion.text }}</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                    <a
                        class="search_all"
                        :class="{ active: suggestions.length }"
                        :href="search_route + '?query=' + search_input"
                    >
                        {{ show_more_text }}
                    </a>
                </div>
            </div>
            <button
                type="button"
                class="search_clear"
                :style="{ display: !!search_input.length ? 'block' : 'none' }"
                @click="clearSearchInput"
            ></button>
            <button type="submit" class="search_submit btn btn_special">
                <span>{{ button_text }}</span>
            </button>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        initialData: String,
    },
    data() {
        const initialData = JSON.parse(this.initialData);

        return {
            showSearch: false,
            search_input: '',
            suggestions: [],
            search_route: initialData?.search_route ?? '',
            suggest_route: initialData?.suggest_route ?? '',
            placeholder_text: initialData?.placeholder_text ?? '',
            button_text: initialData?.button_text ?? '',
            show_more_text: initialData?.show_more_text ?? '',
        };
    },
    created() {
        const searchButton = document.getElementById('header_search');

        if (searchButton) {
            searchButton.addEventListener('click', (e) => {
                this.showSearch = !this.showSearch;
                if (this.showSearch) {
                    this.fetchSuggestions();
                } else {
                    this.suggestions = [];
                }
            });

            window.addEventListener('click', (e) => {
                if (this.showSearch
                    && !e.target.closest('.header_search')
                    && !e.target.closest('.search')
                ) {
                    this.showSearch = false;
                    this.suggestions = [];
                }
            });
        }
    },
    methods: {
        clearSearchInput() {
            this.search_input = '';
            this.suggestions = [];
        },
        async fetchSuggestions() {
            if (this.search_input.length > 2) {
                try {
                    const response = await fetch(`${this.suggest_route}?query=${this.search_input}`);
                    this.suggestions = await response.json();
                } catch (error) {
                    console.error('Error fetching suggestions:', error);
                }
            } else {
                this.suggestions = [];
            }
        },
    }
}
</script>

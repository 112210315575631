<template>
    <div class="options">
        <div class="options_content">
            <div class="options_list">
                <button
                    :class="{ active: value.category_ids.length === 0 }"
                    class="options_el"
                    type="button"
                    @click="clearCategories"
                >
                    <span>{{ all_text }}</span>
                </button>
                <button
                    v-for="(category, index) in categories"
                    :class="{
                        active: value.category_ids.includes(category.id),
                        'options_el_hide': count_to_show <= index,
                    }"
                    class="options_el"
                    type="button"
                    @click="setCategory(category.id)"
                >
                    <i>{{ category.count }}</i>
                    <span>{{ category.name }}</span>
                </button>
                <button
                    class="options_more"
                    :style="[count_to_show < categories.length ? '' : {display: 'none'}]"
                    type="button"
                    @click="showMoreOptions"
                >
                    <span>{{ more_filters_text }}</span>
                </button>
            </div>
        </div>
        <button
            class="options_more"
            :style="[count_to_show < categories.length ? '' : {display: 'none'}]"
            type="button"
            @click="showMoreOptions"
        >
            <span>{{ more_filters_text }}</span>
        </button>
        <div
            class="sort"
            :class="{
                active: showOrder
            }"
        >
            <button
                ref="sort"
                type="button"
                class="sort_btn"
            >
                <span class="sort_btn-title">Sort by:</span>
                <span class="sort_btn-val">{{ current_order_name }}</span>
            </button>
            <div class="sort_list">
                <a
                    v-for="order in orders"
                    @click="setOrderBy(order.id)"
                >
                    {{ order.name }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        orders: {
            type: Array,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
        all_text: {
            type: String,
            required: true,
        },
        more_filters_text: {
            type: String,
            required: true,
        },
        count_categories_to_show: {
            type: Number,
            default: 5,
        },
        count_categories_to_pagination: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            showOrder: false,
            count_to_show: this.count_categories_to_show,
        };
    },
    computed: {
        current_order_name() {
            return this.orders.find((order) => order.id === this.value.order_by)?.name;
        },
    },
    created() {
        setTimeout(() => {
            const sortButton = this.$refs.sort;

            if (sortButton) {
                sortButton.addEventListener('click', (e) => {
                    this.showOrder = !this.showOrder;
                });

                window.addEventListener('click', (e) => {
                    if (this.showOrder
                        && !e.target.closest('.sort_btn')
                        && !e.target.closest('.sort')
                    ) {
                        this.showOrder = false;
                    }
                });
            }
        }, 100);
    },
    methods: {
        showMoreOptions() {
            this.count_to_show += this.count_categories_to_pagination;
        },
        clearOtherFilters() {
            this.value.game_category_ids = [];
            this.value.game_developer_ids = [];
            this.value.payment_method_ids = [];
            this.value.regulator_ids = [];
            this.value.min_deposit_ids = [];
        },
        clearCategories() {
            if (this.value.category_ids.length) {
                this.value.category_ids = [];
                this.clearOtherFilters();
                this.updateOptions();
            }
        },
        setCategory(categoryId) {
            if (!this.value.category_ids.includes(categoryId)) {
                this.value.category_ids = [categoryId];
                this.clearOtherFilters();
                this.updateOptions();
            }
        },
        setOrderBy(order_by) {
            if (this.value.order_by !== order_by) {
                this.value.order_by = order_by;
                this.updateOptions();
            }
            this.showOrder = false;
        },
        updateOptions() {
            this.$emit('input', this.value);
        },
    },
};
</script>

<template>
    <div class="login">
        <form
            class="form user"
            @submit.prevent="onSubmit"
        >
            <div class="form_row">
                <div
                    class="form_el"
                    :class="{ 'has_error': error }"
                >
                    <input
                        ref="email"
                        id="login_email"
                        class="from_control"
                        :class="{ changed: this.form.email }"
                        type="text"
                        v-model="form.email"
                    />
                    <label for="login_email">{{ email_or_phone_label }}</label>
                    <span v-if="error" class="text__error">{{ error }}</span>
                </div>
            </div>
            <div class="form_row">
                <div class="form_el">
                    <input
                        id="login_password"
                        class="from_control"
                        :class="{ changed: this.form.password }"
                        :type="showPassword ? 'text' : 'password'"
                        v-model="form.password"
                    />
                    <label for="login_password">{{ password_label }}</label>
                    <button
                        class="form_view_pswd"
                        type="button"
                        @click="showPassword = !showPassword"
                    ></button>
                </div>
            </div>
            <div class="form_row form_row_sb">
                <div class="form_ch">
                    <input
                        id="login_remember"
                        class="from_control"
                        :class="{ changed: this.form.remember }"
                        type="checkbox"
                        v-model="form.remember"
                    />
                    <label for="login_remember">{{ remember_me_label }}</label>
                </div>
                <button
                    type="button"
                    class="user_recover"
                    @click="toForgot"
                >
                    {{ forgot_password_text }}
                </button>
            </div>
            <button type="submit" style="display: none"></button>
        </form>
        <div class="user_social social">
            <div class="social_sep"><span>{{ or_continue_text }}</span></div>
            <ul class="social_list">
                <li>
                    <button
                        type="button"
                        @click="facebookAuth"
                    >
                        <img
                            loading="lazy"
                            :src="facebook_icon"
                            alt="facebook icon"
                        />
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        @click="googleAuth"
                    >
                        <img
                            loading="lazy"
                            :src="google_icon"
                            alt="google icon"
                        />
                    </button>
                </li>
            </ul>
        </div>
        <footer>
            <button
                type="submit"
                class="form_submit btn btn_special btn_specialL"
                @click="onSubmit"
                :disabled="is_submitting"
            >
                <span>{{ login_text }}</span>
            </button>
        </footer>
    </div>
</template>

<script>
export default {
    props: {
        login_route: String,
        facebook_auth_route: String,
        google_auth_route: String,
        email_or_phone_label: String,
        password_label: String,
        remember_me_label: String,
        facebook_icon: String,
        google_icon: String,
        forgot_password_text: String,
        or_continue_text: String,
        login_text: String,
    },
    data() {
        return {
            showPassword: false,
            is_submitting: false,
            form: {
                _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                email: '',
                password: '',
                remember: false,
            },
            error: null
        };
    },
    methods: {
        clearErrors() {
            this.error = null;
        },
        focus() {
            setTimeout(() => {
                this.$refs.email.focus();
            }, 100);
        },
        toForgot() {
            this.$emit('update:form', 'forgot');
        },
        facebookAuth() {
            window.location.href = this.facebook_auth_route;
        },
        googleAuth() {
            window.location.href = this.google_auth_route;
        },
        onSubmit() {
            this.clearErrors();
            this.is_submitting = true;
            this.error = null;

            const requestOptions = {
                method: 'post',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.form),
            };

            fetch(this.login_route, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.error) {
                        this.error = response.error;
                        console.log(this.error);
                    } else {
                        window.location.reload(true);
                    }
                })
                .catch(error => console.log(error.message))
                .finally(() => this.is_submitting = false);
        }
    }
}
</script>

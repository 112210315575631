<template>
    <popup v-if="is_popup_visible" :wrapper_class="wrapper_class" @close="closeModal">
        <div v-html="modal_content"></div>
    </popup>
</template>

<script>
import Popup from './Popup';

export default {
    components: {Popup},
    data() {
        return {
            wrapper_class: 'info_box_casinos',
            modal_content: '',
            is_popup_visible: false,
        };
    },
    created() {
        document.body.addEventListener('click', (event) => {
            if (
                !(event.target.classList.contains('call-visit-site-popup-js') ||
                event.target.parentElement.classList.contains('call-visit-site-popup-js'))
            ) {
                return;
            }
            if (event.target.parentElement.classList.contains('call-visit-site-popup-js')) {
                event.stopPropagation();
            }
            event.preventDefault();
            const clickedElement = event.target.getAttribute('data-popup-link') ? event.target : event.target.parentElement;
            const popupUrl = clickedElement.getAttribute('data-popup-link');
            if (popupUrl) {
                this.showPopupWithHtmlContent(popupUrl);
            }
        });
    },
    methods: {
        showPopupWithHtmlContent(cardUrl) {
            this.modal_content = '';
            fetch(cardUrl)
                .then(response => response.text())
                .then(html => {
                    this.modal_content = html;
                    this.is_popup_visible = true;
                })
                .catch(error => {
                    console.error('Error fetching HTML content:', error);
                });
        },
        closeModal() {
            this.is_popup_visible = false;
            this.modal_content = '';
        }
    }
}
</script>

<template>
    <div :class="wrapperClasses">
        <div class="info_box_overlay"></div>
        <div class="info_box_body">
            <button
                    type="button"
                    class="info_box_close"
                    @click="close"
            >
            </button>
            <div class="info_box_content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        wrapper_class: {
            type: String,
            default: ''
        }
    },
    computed: {
        wrapperClasses() {
            return ['info_box', 'active', ...this.wrapper_class.split(' ')];
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>

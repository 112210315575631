<template>
    <div class="add_review_el-star">
        <i
            v-for="star in stars"
            :class="{active: star <= rating}"
            @click="setRating(star)"
        ></i>
    </div>
</template>

<script>
export default {
    props: {
        length: {
            type: Number,
            default: 5,
        },
        value: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            stars: [],
            rating: this.value,
        };
    },
    created() {
        this.generateStars();
    },
    methods: {
        generateStars() {
            this.stars = Array.from({ length: this.length }, (_, index) => index + 1);
        },
        setRating(rating) {
            this.rating = this.rating !== rating ? rating : 0;
            this.$emit('input', this.rating);
        }
    }
}
</script>

<template>
    <div class="card_section">
        <h2 class="card_section-title">
            <img :src="title_icon" alt="available casinos icon">
            {{ available_games_in_casino_text }}
        </h2>
        <section class="cards">
            <div class="cards_inner">
                <casino-item
                        v-for="card in cards"
                        :key="card.id"
                        :card="card"
                        :promo_code_text="promo_code_text"
                        :copy_text="copy_text"
                        :copied_text="copied_text"
                        :show_more_text="show_more_text"
                        :hide_more_text="hide_more_text"
                        :visit_site_text="visit_site_text"
                        :reviews_text="reviews_text"
                        :available_games_text="available_games_text"
                        :deposit_methods_text="deposit_methods_text"
                        :min_deposit_text="min_deposit_text"
                        :no_deposit_text="no_deposit_text"
                        :regulator_license_text="regulator_license_text"
                ></casino-item>
            </div>
            <div
                    class="cards_bottom"
                    v-if="current_page < last_page"
            >
                <button
                        type="button"
                        class="cards_more load-more"
                        @click="nextPage"
                >
                    <span>{{ load_10_more_text }}</span>
                </button>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        initialData: String,
    },
    data() {
        const initialData = JSON.parse(this.initialData);

        return {
            active_cards: [],
            current_page: initialData?.current_page ?? null,
            last_page: initialData?.last_page ?? null,
            casinos_route: initialData?.casinos_route ?? '',
            title_icon: initialData?.title_icon ?? '',
            option_icon: initialData?.option_icon ?? '',
            available_games_in_casino_text: initialData?.available_games_in_casino_text ?? '',
            promo_code_text: initialData?.promo_code_text ?? '',
            copy_text: initialData?.copy_text ?? '',
            copied_text: initialData?.copied_text ?? '',
            show_more_text: initialData?.show_more_text ?? '',
            hide_more_text: initialData?.hide_more_text ?? '',
            visit_site_text: initialData?.visit_site_text ?? '',
            reviews_text: initialData?.reviews_text ?? '',
            available_games_text: initialData?.available_games_text ?? '',
            deposit_methods_text: initialData?.deposit_methods_text ?? '',
            min_deposit_text: initialData?.min_deposit_text ?? '',
            no_deposit_text: initialData?.no_deposit_text ?? '',
            load_10_more_text: initialData?.load_10_more_text ?? '',
            regulator_license_text: initialData?.regulator_license_text ?? '',
            cards: initialData?.cards ?? [],
        };
    },
    methods: {
        setCardActive(card_id) {
            if (this.active_cards.includes(card_id)) {
                this.active_cards = this.active_cards.filter((value) => value !== card_id);
            } else {
                this.active_cards.push(card_id);
            }
        },
        nextPage() {
            this.current_page++;

            const params = new URLSearchParams({page: this.current_page});

            const requestOptions = {
                method: 'get',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            };

            fetch(this.casinos_route + '?' + params, requestOptions)
                .then((response) => response.json())
                .then((response) => {
                    response.data.forEach((card) => {
                        this.cards.push(card);
                    });
                })
                .catch(error => console.log(error.message));
        },
    }
}
</script>

<template>
    <popup v-if="is_popup_visible" @close="close">
        <div
            class="info_box_success"
            v-if="is_submitted"
        >
            <div class="info_box_success-icon">
                <img :src="success_icon" loading="lazy" alt="">
            </div>
            <div class="info_box_success-title">{{ success_title_text }}</div>
            <p>{{ success_message_text }}</p>
        </div>
        <form
            v-else
            class="add_review"
            :class="{add_review_disabled: !is_auth}"
            @submit.prevent="onSubmit"
        >
            <div class="add_review_title">{{ header_text }}</div>
            <div class="add_review_author">
                <template v-if="is_auth">
                    {{ logged_in_text }}
                    <a :href="profile_route">
                        <b>{{ username }}</b>
                    </a>
                </template>
                <template v-else>
                    {{ not_logged_in_text }}
                    <button class="add_review_log" @click.stop.prevent="onLoginClick">
                        <img :src="login_button_icon" loading="lazy" alt="login icon">
                    </button>
                </template>
            </div>
            <div class="add_review_list">
                <div
                    class="add_review_list-row"
                    v-for="ratingRow in ratingRows"
                >
                    <div
                        class="add_review_el"
                        v-for="rating in ratingRow"
                    >
                        <div class="add_review_el-title">{{ rating.title }}</div>
                        <rating v-model="review.ratings[rating.id]"></rating>
                    </div>
                </div>
            </div>
            <div class="add_review-comment">
                <textarea v-model="review.text" :placeholder="placeholder_text"></textarea>
            </div>
            <footer>
                <button
                    type="submit"
                    class="add_review-submit btn btn_special"
                    :disabled="is_submitting"
                >{{ button_text }}</button>
            </footer>
        </form>
    </popup>
</template>

<script>
import Popup from './Popup';
import Rating from './Rating'

export default {
    components: {
        Popup,
        Rating,
    },
    props: {
        initialData: String,
    },
    data() {
        const initialData = JSON.parse(this.initialData);

        return {
            is_popup_visible: false,
            is_submitting: false,
            is_submitted: false,
            is_auth: initialData?.is_auth ?? false,
            username: initialData?.username ?? '',
            profile_route: initialData?.profile_route ?? '',
            add_review_route: initialData?.add_review_route ?? '',
            login_button_icon: initialData?.login_button_icon ?? '',
            success_icon: initialData?.success_icon ?? '',
            header_text: initialData?.header_text ?? '',
            logged_in_text: initialData?.logged_in_text ?? '',
            not_logged_in_text: initialData?.not_logged_in_text ?? '',
            placeholder_text: initialData?.placeholder_text ?? '',
            button_text: initialData?.button_text ?? '',
            success_title_text: initialData?.success_title_text ?? '',
            success_message_text: initialData?.success_message_text ?? '',
            ratings: initialData?.ratings ?? [],
            review: {
                _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                card_id: null,
                review_type: 2,
                text: '',
                ratings: initialData?.ratings.reduce((ratings, rating) => {
                    ratings[rating.id] = 0;
                    return ratings;
                }, {}),
            },
        };
    },
    computed: {
        ratingRows: function () {
            return this.chunkArray(this.ratings, 3);
        },
    },
    created() {
        const addReviewButton = document.getElementById('add_review');

        if (addReviewButton) {
            addReviewButton.addEventListener('click', (e) => {
                this.review.card_id = parseInt(e.target.closest('#add_review').dataset.cardId);
                this.open();
            });

            document.addEventListener('click', (e) => {
                if (this.is_popup_visible && e.target.classList.contains('info_box_overlay')) {
                    this.close();
                }
            })
        }
    },
    methods: {
        open() {
            this.is_popup_visible = true;
        },
        close() {
            this.is_popup_visible = false;
        },
        onLoginClick() {
            this.close();
            document.getElementById('header_login').click();
        },
        chunkArray(array, size) {
            let chunks = [];

            while (array.length) {
                chunks.push(array.splice(0, size))
            }

            return chunks;
        },
        onSubmit() {
            this.is_submitting = true;

            const requestOptions = {
                method: 'post',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.review),
            };

            fetch(this.add_review_route, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.message === 'Success') {
                        this.is_submitted = true;
                    }
                })
                .catch(error => console.log(error.message))
                .finally(() => this.is_submitting = false);
        }
    }
}
</script>

<template>
    <ul
        v-if="hasPages"
        class="pages"
    >
        <li
            v-if="onFirstPage"
            class="disabled"
            aria-disabled="true"
            aria-label="previous page"
        >
            <span aria-hidden="true">
                <img
                    loading="lazy"
                    :src="prevIcon"
                    alt="previous page icon"
                />
            </span>
        </li>
        <li v-else>
            <a
                rel="prev"
                aria-label="previous page"
                :href="prevPageUrl"
                @click.prevent="prevPage"
            >
                <img
                    loading="lazy"
                    :src="prevIcon"
                    alt="previous page icon"
                />
            </a>
        </li>
        <template v-for="item in items">
            <li
                v-if="!Array.isArray(item)"
                aria-disabled="true"
            >
                <span class="disabled">{{ item }}</span>
            </li>
            <template
                v-else
                v-for="itemPage in item"
            >
                <li
                    v-if="itemPage === page"
                    aria-current="page"
                >
                    <span class="active">
                        {{ itemPage }}
                    </span>
                </li>
                <li v-else>
                    <a
                        :href="pageUrl(itemPage)"
                        @click.prevent="setPage(itemPage)"
                    >
                        {{ itemPage }}
                    </a>
                </li>
            </template>
        </template>
        <li v-if="hasMorePages">
            <a
                :href="nextPageUrl"
                rel="next"
                aria-label="next page"
                @click.prevent="nextPage"
            >
                <img
                    loading="lazy"
                    :src="nextIcon"
                    alt="next page icon"
                />
            </a>
        </li>
        <li
            v-else
            class="disabled"
            aria-disabled="true"
            aria-label="next page"
        >
            <span aria-hidden="true">
                <img
                    loading="lazy"
                    :src="nextIcon"
                    alt="next page icon"
                />
            </span>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        url: {
            require: true,
            type: String,
        },
        page: {
            require: true,
            type: Number,
        },
        total: {
            require: true,
            type: Number,
        },
        onEachSide: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            initPage: this.page,
            prevIcon: '/css/img/svg/prev.svg',
            nextIcon: '/css/img/svg/next.svg',
        };
    },
    computed: {
        hasPages() {
            return this.total > 1;
        },
        onFirstPage() {
            return this.page <= 1;
        },
        hasMorePages() {
            return this.page < this.total;
        },
        nextPageUrl() {
            return this.pageUrl(this.page + 1);
        },
        prevPageUrl() {
            return this.pageUrl(this.page - 1);
        },
        items() {
            const window = this.window();

            const items = [];

            items.push(window.first);
            items.push(Array.isArray(window.slider) ? '...' : null)
            items.push(window.slider);
            items.push(Array.isArray(window.last) ? '...' : null);
            items.push(window.last);

            return items.filter((item) => item);
        },
    },
    mounted() {
        window.addEventListener('popstate', this.handleURLChange);
    },
    beforeDestroy() {
        window.removeEventListener('popstate', this.handleURLChange);
    },
    methods: {
        urlRange(from, to) {
            const range = []

            from = from > 0 ? from : 1

            for (let i = from; i <= to; i++) {
                range.push(i)
            }

            return range
        },
        window() {
            const onEachSide = this.onEachSide;

            if (this.total < (onEachSide * 2) + 8) {
                return this.smallSlider();
            }

            return this.urlSlider(onEachSide);
        },
        smallSlider() {
            return {
                first: this.urlRange(1, this.total),
                slider: null,
                last: null,
            };
        },
        urlSlider(onEachSide) {
            const window = onEachSide + 4;

            if (!this.hasPages) {
                return {
                    first: null,
                    slider: null,
                    last: null,
                };
            }

            if (this.page <= window) {
                return this.sliderTooCloseToBeginning(window, onEachSide);
            } else if (this.page > (this.total - window)) {
                return this.sliderTooCloseToEnding(window, onEachSide);
            }

            return this.fullSlider(onEachSide);
        },
        sliderTooCloseToBeginning(window, onEachSide) {
            return {
                first: this.urlRange(1, window + onEachSide),
                slider: null,
                last: this.finish(),
            };
        },
        sliderTooCloseToEnding(window, onEachSide) {
            const last = this.urlRange(
                this.total - (window + (onEachSide - 1)),
                this.total
            );

            return {
                first: this.start(),
                slider: null,
                last,
            };
        },
        fullSlider(onEachSide) {
            return {
                first: this.start(),
                slider: this.adjacentUrlRange(onEachSide),
                last: this.finish(),
            };
        },
        adjacentUrlRange(onEachSide) {
            return this.urlRange(
                this.page - onEachSide,
                this.page + onEachSide
            );
        },
        start() {
            return this.urlRange(1, 2);
        },
        finish() {
            return this.urlRange(
                this.total - 1,
                this.total
            );
        },
        isActive(page) {
            return this.page === page;
        },
        pageUrl(page) {
            if (page > 1) {
                return `${this.url}/${page}`;
            }

            return this.url;
        },
        handleURLChange(e) {
            this.setPage(e.state?.page ?? this.initPage, false);
        },
        setPage(page, pushState = true) {
            if (!this.isActive(page)) {
                if (pushState) {
                    window.history.pushState({page}, '', this.pageUrl(page));
                }
                this.$emit("update:page", page);
            }
        },
        prevPage() {
            if (this.page > 1) {
                this.setPage(this.page - 1);
            }
        },
        nextPage() {
            if (this.page < this.total) {
                this.setPage(this.page + 1);
            }
        },
    },
}
</script>

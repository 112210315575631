<template>
    <div class="reg">
        <div class="step">
            <i
                v-for="step in totalSteps"
                :class="{ active: step <= currentStep }"
            ></i>
        </div>
        <template v-if="currentStep === 1">
            <form
                class="reg_step"
                @submit.prevent="nextStep"
            >
                <div class="form_row">
                    <div
                        class="form_el"
                        :class="{ 'has_error': errors.first_name }"
                    >
                        <input
                            ref="first_name"
                            id="registration_first_name"
                            class="from_control"
                            :class="{ changed: this.form.first_name }"
                            type="text"
                            v-model="form.first_name"
                        />
                        <label for="registration_first_name">{{ first_name_label }}</label>
                        <span v-if="errors.first_name" class="text__error">{{ errors.first_name }}</span>
                    </div>
                </div>
                <div class="form_row">
                    <div class="form_el">
                        <div
                            class="form_el"
                            :class="{ 'has_error': errors.email }"
                        >
                            <input
                                id="registration_email"
                                class="from_control"
                                :class="{ changed: this.form.email }"
                                type="text"
                                v-model="form.email"
                            />
                            <label for="registration_email">{{ email_label }}</label>
                            <span v-if="errors.email" class="text__error">{{ errors.email }}</span>
                        </div>
                    </div>
                </div>
                <button type="submit" style="display: none"></button>
            </form>
            <div class="user_social social">
                <div class="social_sep"><span>{{ or_continue_text }}</span></div>
                <ul class="social_list">
                    <li>
                        <button
                            type="button"
                            @click="facebookAuth"
                        >
                            <img
                                loading="lazy"
                                :src="facebook_icon"
                                alt="facebook icon"
                            />
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            @click="googleAuth"
                        >
                            <img
                                loading="lazy"
                                :src="google_icon"
                                alt="google icon"
                            />
                        </button>
                    </li>
                </ul>
            </div>
        </template>
        <template v-if="currentStep === 2">
            <form
                class="reg_step"
                @submit.prevent="nextStep"
            >
                <div class="form_row">
                    <div
                        class="form_el"
                        :class="{ 'has_error': errors.phone }"
                    >
                        <input
                            ref="phone"
                            id="registration_phone"
                            class="from_control"
                            type="text"
                            v-model="form.phone"
                        />
                        <label for="registration_phone">{{ phone_label }}</label>
                        <span v-if="errors.phone" class="text__error">{{ errors.phone }}</span>
                    </div>
                </div>
                <button type="submit" style="display: none"></button>
            </form>
        </template>
        <template v-if="currentStep === 3">
            <form
                class="form reg_step"
                @submit.prevent="nextStep"
            >
                <div class="form_row">
                    <p>{{ password_need_text }}</p>
                    <div
                        class="form_el"
                        :class="{ 'has_error': errors.password }"
                    >
                        <input
                            ref="password"
                            id="registration_password"
                            class="from_control"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="form.password"
                            @input="checkPassword"
                        />
                        <div
                            class="form_view_pswd"
                            @click="showPassword = !showPassword"
                        ></div>
                        <label for="registration_password">{{ password_label }}</label>
                        <span v-if="errors.password" class="text__error">{{ errors.password }}</span>
                    </div>
                </div>
                <ul class="form_pswd-info">
                    <li :class="{ 'is-valid': this.passwordValidator.min }">
                        {{ password_min_text }}
                    </li>
                    <li :class="{ 'is-valid': this.passwordValidator.mixedCase }">
                        {{ password_mixedCase_text }}
                    </li>
                    <li :class="{ 'is-valid': this.passwordValidator.letters }">
                        {{ password_letters_text }}
                    </li>
                    <li :class="{ 'is-valid': this.passwordValidator.symbols }">
                        {{ password_symbols_text }}
                    </li>
                    <li :class="{ 'is-valid': this.passwordValidator.numbers }">
                        {{ password_numbers_text }}
                    </li>
                </ul>
                <div class="form_row">
                    <div
                        class="form_el"
                        :class="{ 'has_error': errors.password_confirmation }"
                    >
                        <input
                            id="registration_password_confirmation"
                            class="from_control"
                            :type="showPasswordConfirmation ? 'text' : 'password'"
                            v-model="form.password_confirmation"
                        />
                        <div
                            class="form_view_pswd"
                            @click="showPasswordConfirmation = !showPasswordConfirmation"
                        ></div>
                        <label for="registration_password_confirmation">{{ password_confirmation_label }}</label>
                        <span v-if="errors.password_confirmation" class="text__error">{{ errors.password_confirmation }}</span>
                    </div>
                </div>
                <button type="submit" style="display: none"></button>
            </form>
        </template>
        <template v-if="currentStep === 4">
            <div class="reg_step">
                <div class="reg_success">
                    <div class="reg_success-icon">
                        <img
                            loading="lazy"
                            :src="thank_registration_icon"
                            alt="thanks icon"
                        />
                    </div>
                    <div class="reg_success-title">{{ thank_registration_text }}</div>
                </div>
            </div>
        </template>
        <footer
            :class="{
                'reg_step_footer': currentStep > 1,
                'reg_success-btns': currentStep === totalSteps
            }"
        >
            <button
                v-if="currentStep < totalSteps && currentStep !== 1"
                type="button"
                class="btn btn_clear"
                @click="prevStep"
                :disabled="is_submitting"
            >
                <span>{{ previous_text }}</span>
            </button>
            <button
                v-if="currentStep < totalSteps"
                type="button"
                class="btn btn_special btn_specialL"
                :class="{ 'form_submit': currentStep === 1 }"
                @click="nextStep"
                :disabled="is_submitting"
            >
                <span>{{ next_text }}</span>
            </button>
        </footer>
    </div>
</template>

<script>
export default {
    props: {
        application_id: Number,
        check_email_route: String,
        check_phone_route: String,
        register_route: String,
        facebook_auth_route: String,
        google_auth_route: String,
        first_name_label: String,
        email_label: String,
        phone_label: String,
        password_label: String,
        password_confirmation_label: String,
        facebook_icon: String,
        google_icon: String,
        thank_registration_icon: String,
        or_continue_text: String,
        previous_text: String,
        next_text: String,
        password_need_text: String,
        password_min_text: String,
        password_mixedCase_text: String,
        password_letters_text: String,
        password_symbols_text: String,
        password_numbers_text: String,
        thank_registration_text: String,
    },
    data() {
        return {
            is_submitting: false,
            is_submitted: false,
            currentStep: 1,
            totalSteps: 4,
            showPassword: false,
            showPasswordConfirmation: false,
            passwordMin: 8,
            passwordValidator: {
                min: false,
                mixedCase: false,
                letters: false,
                symbols: false,
                numbers: false
            },
            form: {
                _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                application_id: this.application_id,
                first_name: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: ''
            },
            errors: {
                first_name: null,
                email: null,
                phone: null,
                password: null,
                password_confirmation: null
            }
        };
    },
    computed: {
        hasErrors() {
            return Object.values(this.errors).some(error => error !== null);
        },
    },
    methods: {
        clearErrors() {
            this.errors.first_name = null;
            this.errors.email = null;
            this.errors.phone = null;
            this.errors.password = null;
            this.errors.password_confirmation = null;
        },
        focus() {
            if (this.currentStep === 1) {
                setTimeout(() => {
                    this.$refs.first_name.focus();
                }, 100);
            } else if (this.currentStep === 2) {
                setTimeout(() => {
                    this.$refs.phone.focus();
                }, 100);
            } else if (this.currentStep === 3) {
                setTimeout(() => {
                    this.$refs.password.focus();
                }, 100);
            }
        },
        prevStep() {
            this.currentStep--;
        },
        async nextStep() {
            this.clearErrors();
            this.is_submitting = true;

            if (this.currentStep === 1) {
                await this.validateUsernameAndEmail();
            } else if (this.currentStep === 2) {
                await this.validatePhone();
            } else if (this.currentStep === 3) {
                await this.registration();
            }

            if (!this.hasErrors) {
                this.currentStep++;
                this.is_submitted = false;
                this.focus();
            }
        },
        facebookAuth() {
            window.location.href = this.facebook_auth_route;
        },
        googleAuth() {
            window.location.href = this.google_auth_route;
        },
        checkPassword() {
            this.passwordValidator = {
                min: this.form.password.length >= this.passwordMin,
                mixedCase: this.form.password.match(/(\p{Ll}+.*\p{Lu})|(\p{Lu}+.*\p{Ll})/u),
                letters: this.form.password.match(/\p{L}/u),
                symbols: this.form.password.match(/\p{Z}|\p{S}|\p{P}/u),
                numbers: this.form.password.match(/\p{N}/u)
            };
        },
        validateUsernameAndEmail() {
            const requestOptions = {
                method: 'post',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    '_token': this.form._token,
                    name: this.form.first_name,
                    email: this.form.email
                }),
            };

            return fetch(this.check_email_route, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.message === 'Success') {
                        this.is_submitted = true;
                    } else if (response.errors) {
                        for (const field in response.errors) {
                            this.errors[field === 'name' ? 'first_name' : field] = response.errors[field][0];
                        }
                    }
                })
                .catch(error => console.log(error.message))
                .finally(() => this.is_submitting = false);
        },
        validatePhone() {
            const requestOptions = {
                method: 'post',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    '_token': this.form._token,
                    phone: this.form.phone
                }),
            };

            return fetch(this.check_phone_route, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.message === 'Success') {
                        this.is_submitted = true;
                    } else if (response.errors) {
                        for (const field in response.errors) {
                            this.errors[field] = response.errors[field][0];
                        }
                    }
                })
                .catch(error => console.log(error.message))
                .finally(() => this.is_submitting = false);
        },
        registration() {
            const requestOptions = {
                method: 'post',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.form),
            };

            return fetch(this.register_route, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.status === 'success') {
                        this.is_submitted = true;
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    } else if (response.errors) {
                        for (const field in response.errors) {
                            this.errors[field] = response.errors[field][0];
                        }
                    }
                })
                .catch(error => console.log(error.message))
                .finally(() => this.is_submitting = false);
        }
    }
}
</script>

<template>
    <div>
        <template v-if="!is_submitted">
            <form class="form user_recovery_pswd">
                <div class="info_box_title tac">{{ new_password }}</div>
                <p class="form_important">{{ new_password_text }}</p>
                <div class="form_row">
                    <div
                        class="form_el"
                        :class="{ 'has_error': errors.password }"
                    >
                        <input
                            ref="password"
                            id="password"
                            :class="{ changed: this.form.password }"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="form.password"
                            @input="checkPassword"
                        >
                        <div
                            class="form_view_pswd"
                            @click="showPassword = !showPassword"
                        ></div>
                        <label for="password">{{ password_label }}</label>
                        <span v-if="errors.password" class="text__error">{{ errors.password }}</span>
                    </div>
                </div>
                <ul class="form_pswd-info">
                    <li :class="{ 'is-valid': this.passwordValidator.min }">
                        {{ password_min_text }}
                    </li>
                    <li :class="{ 'is-valid': this.passwordValidator.mixedCase }">
                        {{ password_mixedCase_text }}
                    </li>
                    <li :class="{ 'is-valid': this.passwordValidator.letters }">
                        {{ password_letters_text }}
                    </li>
                    <li :class="{ 'is-valid': this.passwordValidator.symbols }">
                        {{ password_symbols_text }}
                    </li>
                    <li :class="{ 'is-valid': this.passwordValidator.numbers }">
                        {{ password_numbers_text }}
                    </li>
                </ul>
                <div class="form_row">
                    <div
                        class="form_el"
                        :class="{ 'has_error': errors.password_confirmation }"
                    >
                        <input
                            id="password_confirmation"
                            :class="{ changed: this.form.password_confirmation }"
                            :type="showPasswordConfirmation ? 'text' : 'password'"
                            v-model="form.password_confirmation"
                        >
                        <div
                            class="form_view_pswd"
                            @click="showPasswordConfirmation = !showPasswordConfirmation"
                        ></div>
                        <label for="password_confirmation">{{ password_confirmation_label }}</label>
                        <span v-if="errors.password_confirmation" class="text__error">{{ errors.password_confirmation }}</span>
                    </div>
                </div>
                <footer>
                    <button
                        type="button"
                        class="form_submit btn btn_special btn_specialL"
                        @click="submit"
                        :disabled="is_submitting"
                    >
                        {{ submit_change_text }}
                    </button>
                </footer>
            </form>
        </template>
        <template v-else>
            <div class="info_box_title tac">{{ congratulations_2_text }}</div>
            <div class="form_info">{{ password_updated_text }}</div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        reset_password_route: String,
        reset_token: String,
        reset_email: String,
        password_label: String,
        password_confirmation_label: String,
        password_min_text: String,
        password_mixedCase_text: String,
        password_letters_text: String,
        password_symbols_text: String,
        password_numbers_text: String,
        congratulations_2_text: String,
        password_updated_text: String,
        new_password: String,
        new_password_text: String,
        submit_change_text: String
    },
    data() {
        return {
            is_submitting: false,
            is_submitted: false,
            showPassword: false,
            showPasswordConfirmation: false,
            passwordMin: 8,
            passwordValidator: {
                min: false,
                mixedCase: false,
                letters: false,
                symbols: false,
                numbers: false
            },
            form: {
                _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                reset_token: this.reset_token,
                reset_email: this.reset_email,
                password: '',
                password_confirmation: ''
            },
            errors: {
                password: null,
                password_confirmation: null
            }
        };
    },
    methods: {
        clearErrors() {
            this.errors.password = null;
            this.errors.password_confirmation = null;
        },
        focus() {
            setTimeout(() => {
                this.$refs.password.focus();
            }, 100);
        },
        checkPassword() {
            this.passwordValidator = {
                min: this.form.password.length >= this.passwordMin,
                mixedCase: this.form.password.match(/(\p{Ll}+.*\p{Lu})|(\p{Lu}+.*\p{Ll})/u),
                letters: this.form.password.match(/\p{L}/u),
                symbols: this.form.password.match(/\p{Z}|\p{S}|\p{P}/u),
                numbers: this.form.password.match(/\p{N}/u)
            };
        },
        submit() {
            this.clearErrors();
            this.is_submitting = true;

            const requestOptions = {
                method: 'post',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    '_token': this.form._token,
                    email: this.form.reset_email,
                    token: this.form.reset_token,
                    password: this.form.password,
                    password_confirmation: this.form.password_confirmation
                }),
            };

            return fetch(this.reset_password_route, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.status === 'success') {
                        this.is_submitted = true;
                    } else if (response.errors) {
                        for (const field in response.errors) {
                            this.errors[field] = response.errors[field][0];
                        }
                    }
                })
                .catch(error => console.log(error.message))
                .finally(() => this.is_submitting = false);
        }
    }
}
</script>

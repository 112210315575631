<template>
    <div class="recovery">
        <template v-if="!is_submitted">
            <div class="info_box_title tac">{{ restore_password_header_text }}</div>
            <form class="form user_recovery_pswd">
                <div class="form_info">{{ restore_password_text }}</div>
                <div class="form_row">
                    <div class="form_el" :class="{ has_error: this.errors.email }">
                        <input
                            ref="email"
                            id="recovery_email"
                            :class="{ changed: this.form.email, error: this.errors.email }"
                            type="email"
                            v-model="form.email"
                        >
                        <label for="recovery_email">{{ email_label }}</label>
                        <span v-if="errors.email" class="text__error">{{ errors.email }}</span>
                    </div>
                </div>
            </form>
            <footer class="user_recovery_pswd-footer">
                <button
                    type="button"
                    class="btn btn_clear"
                    @click="toLogin"
                    :disabled="is_submitting"
                >
                    <span>{{ back_to_login_text }}</span>
                </button>
                <button
                    type="button"
                    class="form_submit btn btn_special btn_specialL"
                    @click="submit"
                    :disabled="is_submitting"
                >
                    <span>{{ continue_text }}</span>
                </button>
            </footer>
        </template>
        <template v-else>
            <div class="info_box_title tac">{{ congratulations_text }}</div>
            <div class="form_info">{{ password_sent_link_text }}</div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        forgot_password_route: String,
        phone_label: String,
        email_label: String,
        restore_password_header_text: String,
        or_text: String,
        restore_password_text: String,
        back_to_login_text: String,
        continue_text: String,
        congratulations_text: String,
        password_sent_link_text: String
    },
    data() {
        return {
            is_submitting: false,
            is_submitted: false,
            form: {
                _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                email: ''
            },
            errors: {
                email: null
            }
        };
    },
    methods: {
        clearErrors() {
            this.errors.email = null;
        },
        focus() {
            setTimeout(() => {
                this.$refs.email.focus();
            }, 100);
        },
        toLogin() {
            this.$emit('update:form', 'login');
        },
        submit() {
            this.clearErrors();
            this.is_submitting = true;

            const requestOptions = {
                method: 'post',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    '_token': this.form._token,
                    email: this.form.email
                }),
            };

            return fetch(this.forgot_password_route, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.status === 'success') {
                        this.is_submitted = true;
                    } else if (response.errors) {
                        for (const field in response.errors) {
                            this.errors[field] = response.errors[field][0];
                        }
                    }
                })
                .catch(error => console.log(error.message))
                .finally(() => this.is_submitting = false);
        }
    }
}
</script>

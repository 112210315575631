<template>
    <section class="box">
        <div class="box_inner">
            <div class="box_header">
                <h2 class="box_title box_title_compact tac">
                    {{ most_popular_text }}
                </h2>
            </div>
            <div class="box_content">
                <div class="catalog">
                    <div
                        v-for="game in games"
                        class="catalog-el"
                    >
                        <div class="catalog-top">
                            <div class="catalog-img">
                                <img
                                    loading="lazy"
                                    :src="game.logo"
                                    :alt="game.name"
                                />
                            </div>
                            <a :href="game.url" class="catalog-btn">
                                {{ play_now_text }}
                            </a>
                        </div>
                        <div class="catalog-name">
                            <a :href="game.url">{{ game.name }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box_bottom">
                <pagination
                    :url="base_route"
                    :page="current_page"
                    :total="last_page"
                    @update:page="updatePage"
                ></pagination>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        initialData: String,
    },
    data() {
        const initialData = JSON.parse(this.initialData);

        return {
            base_route: initialData?.base_route ?? '',
            rating_games_route: initialData?.rating_games_route ?? '',
            most_popular_text: initialData?.most_popular_text ?? '',
            play_now_text: initialData?.play_now_text ?? '',
            count_to_paginate: initialData?.count_to_paginate ?? 6,
            current_page: initialData?.current_page ?? null,
            last_page: initialData?.last_page ?? null,
            game_category_id: initialData?.game_category_id ?? null,
            games: initialData?.games ?? [],
        };
    },
    methods: {
        updatePage(page) {
            this.current_page = page;
            this.fetchGames();
        },
        fetchGames() {
            const params = new URLSearchParams({
                game_category_id: this.game_category_id,
                per_page: this.count_to_paginate,
                page: this.current_page,
            });

            const requestOptions = {
                method: 'get',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            };

            fetch(this.rating_games_route + '?' + params, requestOptions)
                .then((response) => response.json())
                .then((response) => {
                    this.games = response.data;
                    this.current_page = response.meta.current_page;
                    this.last_page = response.meta.last_page;
                })
                .catch(error => console.log(error.message));
        },
    },
}
</script>

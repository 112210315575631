<template>
    <section class="box">
        <div class="box_inner">
            <h2
                v-if="show_rating_block_header"
                class="box_title tac"
            >
                {{ rating_block_header_text }}
            </h2>

            <rating-casino-categories
                v-model="options"
                :orders="orders"
                :categories="filters.categories"
                :all_text="all_text"
                :more_filters_text="more_filters_text"
                :count_categories_to_show="count_categories_to_show"
                :count_categories_to_pagination="count_categories_to_pagination"
                @input="updateOptions"
            ></rating-casino-categories>

            <div class="wrap_content">
                <div class="content">
                    <section class="cards">
                        <div class="cards_inner">
                            <template v-if="cards.length > 0">
                                <casino-item
                                    v-for="card in cards"
                                    :key="card.id"
                                    :card="card"
                                    :promo_code_text="promo_code_text"
                                    :copy_text="copy_text"
                                    :copied_text="copied_text"
                                    :show_more_text="show_more_text"
                                    :hide_more_text="hide_more_text"
                                    :visit_site_text="visit_site_text"
                                    :reviews_text="reviews_text"
                                    :regulator_license_text="regulator_license_text"
                                    :available_games_text="available_games_text"
                                    :deposit_methods_text="deposit_methods_text"
                                    :min_deposit_text="min_deposit_text"
                                    :no_deposit_text="no_deposit_text"
                                ></casino-item>
                            </template>
                            <template v-else-if="!loading">
                                <div class="cards_el">
                                    <span>
                                        {{ no_casino_text }}
                                        <a @click="clearFilters" style="text-decoration: underline; cursor: pointer;">
                                            {{ clear_filters_text }}
                                        </a>
                                    </span>
                                </div>
                            </template>
                        </div>

                        <div
                            v-if="canLoadMore"
                            class="box_bottom"
                        >
                            <button
                                class="btn btn_clear load-more"
                                @click="loadMore"
                            >
                                <span>{{ load_10_more_text }}</span>
                            </button>
                        </div>
                    </section>
                </div>

                <rating-casino-filters
                    v-model="options"
                    :filters="filters"
                    :advanced_filters_text="advanced_filters_text"
                    :filter_text="filter_text"
                    :select_casinos_text="select_casinos_text"
                    :game_categories_text="game_categories_text"
                    :game_developers_text="game_developers_text"
                    :payment_methods_text="payment_methods_text"
                    :regulators_text="regulators_text"
                    :min_deposits_text="min_deposits_text"
                    @input="updateOptions"
                ></rating-casino-filters>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        initialData: String,
    },
    data() {
        const initialData = JSON.parse(this.initialData);

        return {
            loading: false,
            card_index_route: initialData?.card_index_route ?? '',
            card_filters_route: initialData?.card_filters_route ?? '',
            show_rating_block_header: initialData?.show_rating_block_header ?? false,
            rating_block_header_text: initialData?.rating_block_header_text ?? '',
            promo_code_text: initialData?.promo_code_text ?? '',
            copy_text: initialData?.copy_text ?? '',
            copied_text: initialData?.copied_text ?? '',
            show_more_text: initialData?.show_more_text ?? '',
            hide_more_text: initialData?.hide_more_text ?? '',
            visit_site_text: initialData?.visit_site_text ?? '',
            reviews_text: initialData?.reviews_text ?? '',
            available_games_text: initialData?.available_games_text ?? '',
            deposit_methods_text: initialData?.deposit_methods_text ?? '',
            min_deposit_text: initialData?.min_deposit_text ?? '',
            no_deposit_text: initialData?.no_deposit_text ?? '',
            no_casino_text: initialData?.no_casino_text ?? '',
            clear_filters_text: initialData?.clear_filters_text ?? '',
            load_10_more_text: initialData?.load_10_more_text ?? '',
            all_text: initialData?.all_text ?? '',
            more_filters_text: initialData?.more_filters_text ?? '',
            advanced_filters_text: initialData?.advanced_filters_text ?? '',
            filter_text: initialData?.filter_text ?? '',
            select_casinos_text: initialData?.select_casinos_text ?? '',
            game_categories_text: initialData?.game_categories_text ?? '',
            game_developers_text: initialData?.game_developers_text ?? '',
            payment_methods_text: initialData?.payment_methods_text ?? '',
            regulators_text: initialData?.regulators_text ?? '',
            regulator_license_text: initialData?.regulator_license_text ?? '',
            min_deposits_text: initialData?.min_deposits_text ?? '',
            count_categories_to_show: initialData?.count_categories_to_show ?? 5,
            count_categories_to_pagination: initialData?.count_categories_to_pagination ?? 5,
            count_to_show: initialData?.count_to_show ?? 10,
            count_to_paginate: initialData?.count_to_paginate ?? 10,
            orders: initialData?.orders ?? [],
            filters: initialData?.filters ?? {
                selected_categories: [],
                selected_game_categories: [],
                selected_game_developers: [],
                selected_payment_methods: [],
                selected_regulators: [],
                selected_min_deposits: [],
                categories: [],
                game_categories: [],
                game_developers: [],
                payment_methods: [],
                regulators: [],
                min_deposits: [],
                count: 0,
            },
            cards: initialData?.cards ?? [],
            options: {
                category_ids: initialData?.filters?.selected_categories ?? [],
                game_category_ids: initialData?.filters?.selected_game_categories ?? [],
                game_developer_ids: initialData?.filters?.selected_game_developers ?? [],
                payment_method_ids: initialData?.filters?.selected_payment_methods ?? [],
                regulator_ids: initialData?.filters?.selected_regulators ?? [],
                min_deposit_ids: initialData?.filters?.selected_min_deposits ?? [],
                order_by: 'recommended',
            },
        };
    },
    computed: {
        canLoadMore() {
            return this.cards.length && this.cards.length < this.filters.count;
        },
    },
    methods: {
        updateOptions() {
            this.loading = true
            this.cards = [];
            this.fetchFilters();
            this.fetchCards();

            setTimeout(() => {
                window.stickyAside.check();
            }, 300);
        },
        clearFilters() {
            this.options.game_category_ids = [];
            this.options.game_developer_ids = [];
            this.options.payment_method_ids = [];
            this.options.regulator_ids = [];
            this.options.min_deposit_ids = [];

            this.updateOptions();
        },
        getParams(is_filter = false) {
            const params = new URLSearchParams();

            if (this.options.category_ids.length) {
                params.set('category_ids', this.options.category_ids.join(','));
            }

            if (this.options.game_category_ids.length) {
                params.set('game_category_ids', this.options.game_category_ids.join(','));
            }

            if (this.options.game_developer_ids.length) {
                params.set('game_developer_ids', this.options.game_developer_ids.join(','));
            }

            if (this.options.payment_method_ids.length) {
                params.set('payment_method_ids', this.options.payment_method_ids.join(','));
            }

            if (this.options.regulator_ids.length) {
                params.set('regulator_ids', this.options.regulator_ids.join(','));
            }

            if (this.options.min_deposit_ids.length) {
                params.set('min_deposit_ids', this.options.min_deposit_ids.join(','));
            }

            if (!is_filter && this.options.order_by.length) {
                params.set('order_by', this.options.order_by);
            }

            if (!is_filter) {
                params.set('offset', this.cards.length);
            }

            if (!is_filter) {
                params.set('limit', this.cards.length ? this.count_to_paginate : this.count_to_show);
            }

            return params;
        },
        fetchFilters() {
            const params = this.getParams(true);

            const url = this.card_filters_route + (params.size ? '?' + decodeURIComponent(params) : '');

            const requestOptions = {
                method: 'get',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((response) => {
                    this.filters = response;
                })
                .catch(error => console.log(error.message));
        },
        fetchCards() {
            const params = this.getParams();

            const url = this.card_index_route + (params.size ? '?' + decodeURIComponent(params) : '');

            const requestOptions = {
                method: 'get',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((response) => {
                    response.data.forEach((card) => this.cards.push(card));
                    this.loading = false;
                })
                .catch(error => console.log(error.message));
        },
        loadMore() {
            this.fetchCards();
        },
    },
};
</script>

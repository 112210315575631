var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_section"},[_c('h2',{staticClass:"card_section-title"},[_c('img',{attrs:{"src":_vm.title_icon,"alt":"available games icon"}}),_vm._v("\n        "+_vm._s(_vm.available_games_text)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"options"},[_c('div',{staticClass:"options_content"},[_c('div',{staticClass:"options_list options_list-all_desktop"},[_c('button',{class:{
                        options_el: true,
                        active: _vm.game_category_id === null,
                    },attrs:{"type":"button"},on:{"click":function($event){return _vm.setGameCategory(null)}}},[_c('span',[_vm._v(_vm._s(_vm.all_text))])]),_vm._v(" "),_vm._l((_vm.game_categories),function(game_category){return _c('button',{class:{
                        options_el: true,
                        active: _vm.game_category_id === game_category.id,
                        disabled: !_vm.isActive(game_category.id),
                    },attrs:{"type":"button","data-title":_vm.no_available_game_text},on:{"click":function($event){return _vm.setGameCategory(game_category.id)}}},[_c('span',[_c('img',{attrs:{"loading":"lazy","src":game_category.logo ?? _vm.option_icon,"alt":game_category.name}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(game_category.name))])])])})],2)])]),_vm._v(" "),_c('div',{staticClass:"catalog"},_vm._l((_vm.games),function(game){return _c('div',{staticClass:"catalog-el"},[_c('div',{staticClass:"catalog-top"},[_c('div',{staticClass:"catalog-img"},[_c('img',{attrs:{"loading":"lazy","src":game.logo,"alt":game.name}})]),_vm._v(" "),_c('a',{staticClass:"catalog-btn",attrs:{"href":game.url}},[_vm._v(_vm._s(_vm.play_now_text))])]),_vm._v(" "),_c('div',{staticClass:"catalog-name"},[_c('a',{attrs:{"href":game.url}},[_vm._v(_vm._s(game.name))])])])}),0),_vm._v(" "),(_vm.current_page < _vm.last_page)?_c('div',{staticClass:"cards_bottom"},[_c('button',{staticClass:"cards_more load-more",attrs:{"type":"button"},on:{"click":_vm.loadMore}},[_c('span',[_vm._v(_vm._s(_vm.more_text))])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
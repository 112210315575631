<template>
    <popup v-if="is_popup_visible" :wrapper_class="wrapper_class" @close="close">
        <div
            class="tabs"
            v-if="['login', 'registration'].includes(form)"
        >
            <nav class="tabs_header tabs_header_log tabs_header_noscroll">
                <ul>
                    <li>
                        <button
                            type="button"
                            :class="{ active: form === 'registration' }"
                            @click="toRegistration"
                        >{{ registration_text }}</button>
                    </li>
                    <li>
                        <button
                            type="button"
                            :class="{ active: form === 'login' }"
                            @click="toLogin"
                        >{{ login_text }}</button>
                    </li>
                </ul>
            </nav>
            <div class="tabs_content">
                <div
                    class="tabs_panel"
                    :class="{ active: form === 'registration' }"
                >
                    <register
                        ref="registration"
                        :application_id="application_id"
                        :check_email_route="check_email_route"
                        :check_phone_route="check_phone_route"
                        :register_route="register_route"
                        :facebook_auth_route="facebook_auth_route"
                        :google_auth_route="google_auth_route"
                        :first_name_label="first_name_label"
                        :email_label="email_label"
                        :phone_label="phone_label"
                        :password_label="password_label"
                        :password_confirmation_label="password_confirmation_label"
                        :facebook_icon="facebook_icon"
                        :google_icon="google_icon"
                        :thank_registration_icon="thank_registration_icon"
                        :or_continue_text="or_continue_text"
                        :previous_text="previous_text"
                        :next_text="next_text"
                        :password_need_text="password_need_text"
                        :password_min_text="password_min_text"
                        :password_mixedCase_text="password_mixedCase_text"
                        :password_letters_text="password_letters_text"
                        :password_symbols_text="password_symbols_text"
                        :password_numbers_text="password_numbers_text"
                        :thank_registration_text="thank_registration_text"
                    ></register>
                </div>
                <div
                    class="tabs_panel"
                    :class="{ active: form === 'login' }"
                >
                    <login
                        ref="login"
                        :login_route="login_route"
                        :facebook_auth_route="facebook_auth_route"
                        :google_auth_route="google_auth_route"
                        :email_or_phone_label="email_or_phone_label"
                        :password_label="password_label"
                        :remember_me_label="remember_me_label"
                        :facebook_icon="facebook_icon"
                        :google_icon="google_icon"
                        :forgot_password_text="forgot_password_text"
                        :or_continue_text="or_continue_text"
                        :login_text="login_text"
                        @update:form="changeForm"
                    ></login>
                </div>
            </div>
        </div>
        <template v-else-if="form === 'forgot'">
            <forgot
                ref="forgot"
                :forgot_password_route="forgot_password_route"
                :email_label="email_label"
                :restore_password_header_text="restore_password_header_text"
                :restore_password_text="restore_password_text"
                :back_to_login_text="back_to_login_text"
                :continue_text="continue_text"
                :congratulations_text="congratulations_text"
                :password_sent_link_text="password_sent_link_text"
                @update:form="changeForm"
            ></forgot>
        </template>
        <template v-else>
            <recovery
                ref="recovery"
                :reset_password_route="reset_password_route"
                :reset_token="reset_token"
                :reset_email="reset_email"
                :password_label="password_label"
                :password_confirmation_label="password_confirmation_label"
                :password_min_text="password_min_text"
                :password_mixedCase_text="password_mixedCase_text"
                :password_letters_text="password_letters_text"
                :password_symbols_text="password_symbols_text"
                :password_numbers_text="password_numbers_text"
                :congratulations_2_text="congratulations_2_text"
                :password_updated_text="password_updated_text"
                :new_password="new_password"
                :new_password_text="new_password_text"
                :submit_change_text="submit_change_text"
                @update:form="changeForm"
            ></recovery>
        </template>
    </popup>
</template>

<script>
import Popup from './Popup';
import Login from './Login';
import Register from './Register';
import Forgot from './Forgot.vue';
import Recovery from './Recovery';

export default {
    components: {
        Popup,
        Login,
        Register,
        Forgot,
        Recovery
    },
    props: {
        initialData: String,
    },
    data() {
        const initialData = JSON.parse(this.initialData);

        return {
            is_popup_visible: false,
            wrapper_class: 'info_box_fm info_box_rc',
            form: 'login',
            application_id: initialData?.application_id ?? null,
            reset_token: initialData?.reset_token ?? '',
            reset_email: initialData?.reset_email ?? '',
            login_route: initialData?.login_route ?? '',
            check_email_route: initialData?.check_email_route ?? '',
            check_phone_route: initialData?.check_phone_route ?? '',
            register_route: initialData?.register_route ?? '',
            forgot_password_route: initialData?.forgot_password_route ?? '',
            reset_password_route: initialData?.reset_password_route ?? '',
            facebook_auth_route: initialData?.facebook_auth_route ?? '',
            google_auth_route: initialData?.google_auth_route ?? '',
            first_name_label: initialData?.first_name_label ?? '',
            email_label: initialData?.email_label ?? '',
            phone_label: initialData?.phone_label ?? '',
            password_label: initialData?.password_label ?? '',
            password_confirmation_label: initialData?.password_confirmation_label ?? '',
            email_or_phone_label: initialData?.email_or_phone_label ?? '',
            remember_me_label: initialData?.remember_me_label ?? '',
            facebook_icon: initialData?.facebook_icon ?? '',
            google_icon: initialData?.google_icon ?? '',
            thank_registration_icon: initialData?.thank_registration_icon ?? '',
            login_text: initialData?.login_text ?? '',
            registration_text: initialData?.registration_text ?? '',
            or_continue_text: initialData?.or_continue_text ?? '',
            back_to_login_text: initialData?.back_to_login_text ?? '',
            continue_text: initialData?.continue_text ?? '',
            previous_text: initialData?.previous_text ?? '',
            next_text: initialData?.next_text ?? '',
            forgot_password_text: initialData?.forgot_password_text ?? '',
            password_need_text: initialData?.password_need_text ?? '',
            password_min_text: initialData?.password_min_text ?? '',
            password_mixedCase_text: initialData?.password_mixedCase_text ?? '',
            password_letters_text: initialData?.password_letters_text ?? '',
            password_symbols_text: initialData?.password_symbols_text ?? '',
            password_numbers_text: initialData?.password_numbers_text ?? '',
            thank_registration_text: initialData?.thank_registration_text ?? '',
            restore_password_header_text: initialData?.restore_password_header_text ?? '',
            restore_password_text: initialData?.restore_password_text ?? '',
            congratulations_text: initialData?.congratulations_text ?? '',
            password_sent_link_text: initialData?.password_sent_link_text ?? '',
            congratulations_2_text: initialData?.congratulations_2_text ?? '',
            password_updated_text: initialData?.password_updated_text ?? '',
            new_password: initialData?.new_password ?? '',
            new_password_text: initialData?.new_password_text ?? '',
            submit_change_text: initialData?.submit_change_text ?? '',
        };
    },
    created() {
        if (this.reset_token && this.reset_email) {
            this.form = 'recovery';
            this.is_popup_visible = true;
            setTimeout(() => {
                this.$refs.recovery.focus();
            }, 100);
        }

        const authButton = document.getElementById('header_login');

        if (authButton) {
            authButton.addEventListener('click', (e) => {
                this.open();
            });

            document.addEventListener('click', (e) => {
                if (this.is_popup_visible && e.target.classList.contains('info_box_overlay')) {
                    this.close();
                }
            })
        }
    },
    methods: {
        open() {
            this.toLogin();
            this.is_popup_visible = true;
        },
        close() {
            this.is_popup_visible = false;

            if(this.$refs?.registration.currentStep === 4) {
                window.location.reload();
            }
        },
        changeForm(form) {
            this.form = form;

            if (form === 'forgot') {
                setTimeout(() => {
                    this.$refs.forgot.focus();
                }, 100);
            }
        },
        toRegistration() {
            this.form = 'registration';
            setTimeout(() => {
                this.$refs.registration.focus();
            }, 100);
        },
        toLogin() {
            this.form = 'login';
            setTimeout(() => {
                this.$refs.login.focus();
            }, 100);
        }
    }
}
</script>
